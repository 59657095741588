@import "../../../styles/index.css";

.messageBox {
  background-color: var(--primary-color);
  padding: 17px 20px 17px 20px;
  /* padding: 20px; */
  color: white;
  display: inline-block;
  /* max-width: 80%; */
  margin-bottom: 10px;
  border-color: transparent;
  max-width: 50%;
  /* width: 55%; */
}

.message-box-adjust1 {
  border-radius: 20px 20px 20px 0px;
}

.message-box-adjust2 {
  border-radius: 20px 20px 0px 20px;
}

.messageText {
  font-family: Ubuntu-Light;
  width: 100%;
  /* float: left; */
  font-size: 20px;
  word-wrap: break-word;
  margin: 5px 0px;
}

.messageText a {
  color: white;
  text-underline-offset: 2px;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.buttonText {
  font-family: Ubuntu-Bold;
  color: white;
  background: var(--secondary-color);
  /* padding: 15px 100px; */
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 60px;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.5s, background 0.5s;
  margin-top: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: black;
  font-family: Ubuntu-Medium;
}

.colorDark {
  color: white;
}

.backgroundBlue {
  background: white;
}

.backgroundLight {
  background-color: var(--primary-color);
}

.button {
  cursor: pointer;
  font-family: Ubuntu-Light;
  font-size: 20px;
}

.backgroundOrange {
  background: var(--tertiary-color);
  font-family: Ubuntu-Medium;
}

.messageImg {
  width: 100%;
}

.fq-question {
  font-family: Ubuntu-Medium;
}

.fq-text {
  font-family: Ubuntu-Medium;
  font-size: 20px;
}

@media (min-width: 0px) and (max-width: 480px) {
  .messageBox {
    padding: 18px;
    max-width: 60%;
  }

  .messageText {
    font-family: Ubuntu-Light;
    width: 100%;
    font-size: 16px;
    word-wrap: break-word;
    margin: 5px 0px;
  }

  .buttonText {
    font-size: 1.5rem;
    width: 130px;
    height: 35px;
  }

  .colorWhite {
    color: black;
    font-family: Ubuntu-Medium;
  }

  .fq-question {
    font-family: Ubuntu-Medium;
  }

  .message-box-width {
    width: 50%;
  }
}
