.messages {
  padding: 3px 0 0 0;
  overflow: auto;
}

.messages-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: auto;
  height: 100%;
}

@media (max-width: 480px) {
  .messages {
    padding: 0 10px;
  }
}
