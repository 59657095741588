.more-info-main-content {
  width: 70%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.more-info-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.more-info-img {
  margin-top: 5%;
  width: 10%;
}

.more-info-text {
  font-size: 2rem;
  color: white;
  text-align: center;
  font-family: Ubuntu-Light;
}

.icons-container {
  text-align: center;
}

.more-info-icon {
  margin: 1px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mi-salir-button {
  position: absolute;
  right: 10%;
  top: 5%;
  font-family: Ubuntu-Bold;
  font-size: 18px;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
}

.mi-salir-button:hover {
  color: #ffaa00;
}

.more-info-terminos {
  background-color: #5f059f;
  border: none;
  cursor: pointer;
  align-self: stretch;
  padding: 30px;
  color: white;
  font-family: Ubuntu-Regular;
  font-size: 1.5rem;
  cursor: pointer;
}

.more-info-terminos:hover {
  color: #ffaa00;
}

.text-bold {
  font-family: Ubuntu-Medium;
}

.contacto-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-line {
  margin: 10px 0 10px 0;
}

.mail-button {
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mail-button:hover {
  color: #ffaa00;
}

.redes-sociales-container {
  margin-bottom: 30px;
}

.svg-buttons:hover {
  filter: invert(40%) sepia(22%) saturate(5795%) hue-rotate(1deg)
    brightness(104%) contrast(106%);
}

@media (min-width: 0px) and (max-width: 480px) {
  .more-info-img {
    margin-top: 25%;
    width: 45%;
  }
  .more-info-icon img {
    width: 90%;
  }
}
