@import "../../styles/index.css";

/*Container general*/
.container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
  z-index: 999;
}

.container-background {
  background-image: url("../../assets/imgs/ondasFondo.svg");
  background-repeat: no-repeat;
  background-position: 100% 0%;
}

/*Contenido principal container*/
.main-content {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 100%;
}

.intro-center {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
}

/*Texto Principal & Secundario*/
.text-container {
  height: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.welcome-text {
  font-size: 48px;
  color: white;
  text-align: center;
  font-family: Ubuntu-Medium;
}
.main-text {
  /* position: sticky; */
  font-size: 3rem;
  color: white;
  text-align: center;
  font-family: Ubuntu-Regular;
}

.secondary-text {
  font-family: Ubuntu-Light;
  margin-top: -1px;
  color: white;
  font-size: 20px;
  /* color: rgba(255, 255, 255, 0.8); */
  text-align: center;
}

/*Imagen*/
.img-container {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-img {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-img {
  max-height: 100%;
  max-width: 100%;
}

/*Botones*/
.button-container {
  height: 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-button {
  background-color: #00e2b6;
  border: none;
  color: white;
  cursor: pointer;
  height: 70px;
  width: 400px;
  max-height: 70px;
  max-width: 400px;
  border-radius: 60px;
  font-size: 2.3rem;
  font-family: Ubuntu-Bold;
  transition: color 0.5s, background 0.5s;
}

.main-button:hover {
  background-color: #07bb98;
}

.transparent-button {
  background-color: transparent;
  border: 1px solid #ab88c2;
  color: #e4caf5;
  cursor: pointer;
  /* padding: 20px 140px; */
  height: 70px;
  width: 400px;
  border-radius: 60px;
  font-size: 2.3rem;
  font-family: Ubuntu-Bold;
}

.main-button-margin {
  margin-top: 45px;
}

.secondary-buttons {
  font-family: Ubuntu-Regular;
  background-color: #53049dd1;
  border: none;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  padding: 25px 60px;
  font-size: 20px;
  transition: color 0.5s, background 0.5s;
}
.secondary-buttons:hover {
  color: white;
  background-color: orange;
}
/* .secondary-buttons:focus {
   color: white;
   background-color: orange;
 } */

.bg-orange {
  background-color: var(--tertiary-color) !important;
}

.buttons-container {
  width: 40rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.buttons-container .secondary-buttons {
  margin: 1rem;
}

.gender-buttons-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gender-buttons-container .secondary-buttons {
  margin: 10px;
}

.age-buttons-container {
  display: grid;
  grid-template-columns: repeat(4, 100px);
  gap: 15px;
}

.age-buttons {
  font-family: Ubuntu-Regular;
  background-color: #53049dd1;
  border: none;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  padding: 25px 25px;
  font-size: 20px;
  transition: color 0.5s, background 0.5s;
}
.age-buttons:hover {
  color: white;
  background-color: orange;
}

.popup-button {
  position: relative;
  font-family: Ubuntu-Regular;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 2rem;
  top: 20px;
}

.popup-button:hover {
  color: #ffaa00;
}

/*Vectores*/
.star-vector {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 30px;
  left: -50px;
}

.ondas-vector {
  position: absolute;
  /* left: 50.35%; */
  /* right: -5.59%; */
  /* top: -7.11%; */
  /* bottom: 68.67%; */
  color: #ffffff;
  top: 0;
  right: 0;
}

.star-rotated-vector {
  position: absolute;
  top: 30%;
  right: 10%;
}

.dot-vector {
  /* left: 35%;
    right: 64.24%;
    top: 66.86%;
    bottom: 31.93%; */
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: 23%;
  left: 27%;
}

.dot-vector-adjust {
  position: absolute;
  top: 75%;
  left: 27% !important;
}

.dot-vector-intro {
  position: absolute;
  left: 30%;
  bottom: 31.93%;
}

.dots-vector-intro {
  position: relative;
  align-self: center;
  right: 0;
  left: 0;
  top: -20px;
}

.flecha-atras-vector {
  position: absolute;
  top: 2%;
  right: -20%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  /* z-index: 999; */
}

.button-icono {
  position: relative;
  align-self: center;
  top: 2px;
  left: 10px;
}

.icono-pregunta {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pregunta-icono-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  left: 5%;
}

.secondary-text-age {
  position: relative;
  font-size: 20px;
  color: white;
  text-align: center;
  font-family: Ubuntu-Medium;
  /* padding-right: 10px; */
}

.warning-text {
  position: relative;
  top: 5%;
}

.bold-text {
  font-family: Ubuntu-Medium;
  font-size: 3.1rem;
}

.onboarding-text {
  position: relative;
  top: 30px;
  font-size: 3.6rem;
  color: white;
  text-align: center;
  font-family: Ubuntu-Medium;
}

.nuevo-genero-container {
  position: absolute;
  height: 300px;
  width: 300vw;
  flex-direction: column;
  justify-content: center !important;
  /* align-items: center; */
}

.nuevo-genero-adjust {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.nuevo-genero-adjust h1 {
  position: absolute;
  top: 50px;
}

.nuevo-genero-container-text {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.nuevo-genero-text {
  position: absolute;
  font-size: 5rem;
  font-family: Ubuntu-Regular;
  color: white;
  text-align: center;
  align-self: center;
  top: 20%;
  right: 0;
  bottom: 0;
  left: 0;
}

.width-adjust {
  width: 100vw;
}

.stars-lottie {
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

@media (min-width: 0px) and (max-width: 480px) {
  .container {
    width: 100%;
    height: 100%;
  }
  .main-content {
    margin: 0;
    width: 100%;
  }

  .welcome-text {
    font-size: 3rem;
  }

  .text-container {
    position: sticky;
    width: 90%;
  }

  .main-text {
    font-size: 2rem;
  }

  .bold-text {
    font-family: Ubuntu-Medium;
    font-size: 1.9rem;
  }

  .main-button {
    width: 320px;
    height: 70px;

    font-size: 2rem;
    font-family: Ubuntu-Bold;
  }

  .transparent-button {
    height: 70px;
    width: 320px;
  }

  .star-rotated-vector {
    top: 250px;
    right: 40px;
  }

  .text-line {
    position: static;
    color: white;
  }

  /*Elige tu sexo*/
  .secondary-buttons {
    padding: 25px 50px;
    font-size: 20px;
  }

  .buttons-container {
    width: 100%;
    flex-wrap: wrap;
  }

  /*Elige tu genero*/
  .gender-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .gender-buttons-container .secondary-buttons {
    padding: 20px 40px;
    font-size: 18px;
    transition: color 0.5s, background 0.5s;
  }
  .gender-buttons-container .secondary-buttons:nth-child(4) {
    padding: 20px 15%;
  }
  /*edad botones*/
  .age-buttons-container {
    grid-template-columns: repeat(4, 80px);
    gap: 10px;
  }

  .age-buttons {
    padding: 20px;
    font-size: 20px;
  }
  .img-container {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dots-vector-intro {
    position: relative;
    align-self: center;
    right: 0px;
    left: 0px;
    top: 100px;
  }

  .img-container .dot-vector-intro {
    left: 20px;
  }

  .pregunta-icono-container {
    position: relative;
    display: block;
    left: 0;
  }

  .text-line {
    margin-top: 10px;
  }

  .onboarding-text {
    position: relative;
    font-size: 3rem;
    top: 20px;
  }

  .dot-vector {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 23%;
    left: 8%;
  }

  .star-rotated-vector {
    display: none;
  }

  .flecha-atras-vector {
    position: absolute;
    top: 2%;
    right: 4%;
    cursor: pointer;
    background-color: transparent;
    border: none;
    /* z-index: 999; */
  }

  .star-vector {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 30px;
    left: 30px;
  }

  .dot-vector-adjust {
    position: absolute;
    top: 75%;
    left: 10% !important;
  }
}

@media (min-width: 1920px) {
  .gender-buttons-container {
    width: 60%;
  }
}
