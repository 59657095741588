@font-face {
  font-family: Ubuntu-Italic;
  src: url("../assets/fonts/Ubuntu-Italic.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: Ubuntu-BoldItalic;
  src: url("../assets/fonts/Ubuntu-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: Ubuntu-LightItalic;
  src: url("../assets/fonts/Ubuntu-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: Ubuntu-MediumItalic;
  src: url("../assets/fonts/Ubuntu-MediumItalic.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url("../assets/fonts/Ubuntu-Bold.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Ubuntu-Light;
  src: url("../assets/fonts/Ubuntu-Light.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Ubuntu-Medium;
  src: url("../assets/fonts/Ubuntu-Medium.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Ubuntu-Regular;
  src: url("../assets/fonts/Ubuntu-Regular.woff2") format("woff2"),
    url("../assets/fonts/Ubuntu-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
