.popup-container {
  position: fixed;
  top: 0;
  /* left: 0; */
  width: 100vw;
  height: 100%;
  background-color: rgba(19, 2, 2, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  border-radius: 20px;
  position: relative;
  padding: 32px;
  height: 100%;
  width: 100%;
  max-width: 540px;
  max-height: 340px;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.estrellas-popup {
  position: relative;
  top: 15%;
  right: 35%;
}

.close-button {
  background-color: #58dfb7;
  border: none;
  color: white;
  cursor: pointer;
  /* padding: 20px 60px; */
  height: 60px;
  width: 150px;
  border-radius: 60px;
  font-size: 18px;
  transition: color 0.5s, background 0.5s;
  font-family: Ubuntu-Bold;
  cursor: pointer;
  margin: 20px;
}

.popup-title {
  font-family: Ubuntu-Bold;
  font-size: 2.5rem;
  color: white;
  text-align: center;
  margin: 20px;
}
.popup-text {
  font-family: Ubuntu-Regular;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.818);
  text-align: center;
  margin: 20px;
  text-align: justify;
}

.container-popup-adjust {
  width: 100vw;
}

.input-popup {
  font-family: Ubuntu-Regular;
  font-size: 18px;
  background: none;
  color: #dcc6eb;
  padding-top: 20px;
  margin-bottom: 25px;
  border: none;
  outline: 0px;
  border-bottom: 1px solid white;
  width: 350px;
  height: 30px;
  text-align: center;
}

.input-popup::placeholder {
  color: #dcc6eb;
}

@media (min-width: 0px) and (max-width: 480px) {
  .popup-inner {
    margin-left: 30px;
    margin-right: 30px;
    max-height: 400px;
  }

  .popup-container {
    position: fixed;
    top: 0;
    /* left: 0; */
    width: 100vw;
    height: 100%;
    background-color: rgba(19, 2, 2, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .estrellas-popup {
    position: relative;
    top: 0%;
    right: 45%;
  }

  .close-button {
    height: 70px;
    width: 200px;
    font-size: 2.2rem;
    margin-top: 50px;
  }

  .popup-title {
    font-family: Ubuntu-Bold;
    font-size: 2rem;
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  .popup-text {
    font-family: Ubuntu-Regular;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.818);
    text-align: center;
    margin-top: 20px;
  }

  .input-popup {
    font-family: Ubuntu-Regular;
    font-size: 18px;
    background: none;
    color: #dcc6eb;
    padding-top: 20px;
    border: none;
    outline: 0px;
    border-bottom: 1px solid white;
    margin-bottom: 25px;
    width: 350px;
    height: 30px;
  }

  input-popup::placeholder {
    color: #dcc6eb;
  }
}
