@import "./fonts.css";

:root {
  --primary-color: #7200be /*Fondo*/;
  --primary-dark-color: #440072; /*Opciones de seleccion Sexo, etc */
  --secondary-color: #00e2b6;
  --secondary-dark-color: #07bb98;
  --tertiary-color: #ffaa00; /*Botones, textos */
  --tertiary-dark-color: #e29804; /*Botones, textos */
}

::-webkit-scrollbar {
  background-color: transparent;
}

html {
  font-size: 12px;
  font-family: Ubuntu-Medium, Ubuntu-Regular, Ubuntu-Bold;
  font-display: block;
  -webkit-font-smoothing: antialiased;
}
